import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneArrowUp, faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "components/Layout"
import Seo from 'components/Seo'

const ReportSupportPage = () => {

  /**
   * Renders the main navigation area
   * @returns {JSX.Element}
   */
   const CTAButtons = () => {
    return(
      <nav className="grid grid-cols-2 mt-4 mb-6 text-center text-black border-y-2 md:gap-4 md:border-y-0 border-tecsight-blue">
        <OutboundLink href="tel://0482083810" className="flex flex-col justify-between py-8 font-bold bg-tecsight-yellow md:rounded-lg">
          <FontAwesomeIcon 
            size="2x" 
            className="mr-4 fill-current" 
            icon={faPhoneArrowUp} />
          <span className="pt-6">0482 083 810</span>
        </OutboundLink>
        <OutboundLink 
          href="mailto:support@tecsight.com.au"
          className="flex flex-col items-center justify-between p-8 font-bold bg-tecsight-green md:rounded-lg"
          >
          <FontAwesomeIcon 
            size="2x" 
            className="fill-current" 
            icon={faEnvelope} />
          <span className="pt-6">Email Support</span>
        </OutboundLink>
      </nav>
    );
  }

  return(
    <Layout>
      <Seo 
        title="Remote Support"
        description="A call away 7am-10pm, seven days a week, to help you with your technical issues."
      />
      <h1 className="mb-2 text-3xl font-bold text-center text-tecsight-blue">Remote Support</h1>
      <CTAButtons/>
      <article className="px-4 text-gray-600">
      <p className="mb-4">
          TECSight support is only ever a call away – <b>7am to 10pm, 7 days 
          a week</b> peace of mind. Anything you need, from checking trouble codes 
          right through to major breakdown support. 
      </p>
      <p className="mb-4">
          Connecting your machines is <b>free</b>. Just 10 minutes and you could save 
          hours on a breakdown or setup issue.
      </p>
      <p className="mb-4">
          Remote access to your machine has never been better. 
          We can track trouble codes, remote access the display and help with 
          diagnostics quickly and efficiently.
      </p>
      <p className="mb-4">
          TECSight can talk you through best management of your machines, 
          farms and fields via your free Operations Center account. 
          It makes managing setup files easy and is essential for smooth and 
          trouble-free operations as we move towards autonomous machines.
      </p>
      <p className="mb-4">
          If phone support can’t solve the problem on the spot, we’ll 
          organise technicians from your local branch to come to you and get 
          the job done quickly and efficiently &mdash; saving you time and money.
      </p>
      </article>
    </Layout>
  )
}

export default ReportSupportPage